import { createRouter, createWebHashHistory } from "vue-router";

import JobsRoutes from "@/jobs/jobs.router";
import JobsViewRoutes from "@/jobs-view/jobs-view.router";
import CandidateRouter from "@/candidate/candidate.router";
import CandidateJobsRoutes from "@/candidate-jobs/candidate-jobs.router";
import LoginRoutes from "@/login/login.router";
import EmployerRoutes from "@/employer/employer.router";
import HRRoutes from "@/hr-company/hr.router";
import HrJobRouter from "@/hr-employerjobs/hr-job.router";
import ProfileRoutes from "@/profile-edit/profile-edit.router";
import AdminRouter from "@/admin/admin.router";
import SettingsRouter from "@/settings/settings.router";

import store from "./global-store.index";
import NotificationsRoutes from "@/notification/notifications.router";

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/pages/HomeView.vue"),
      meta: { allowed_user_types: ["all"] }
    },

    {
      path: "/waitlist",
      name: "waitlist",
      component: () => import("././pages/WaitlistView.vue"),
      meta: { authNotRequired: true }
    },
    {
      path: "/email-verification-callback/:token",
      name: "email.verification.callback",
      component: () => import("././pages/EmailVerificationCallback.vue"),
      meta: { authNotRequired: true }
    },

    {
      path: "/typography-test",
      name: "typography_test",
      meta: { allowed_user_types: ["all"], authNotRequired: true },
      component: () => import("@/pages/TypographyTest.vue")
    },
    ...JobsRoutes,
    ...JobsViewRoutes,
    ...CandidateJobsRoutes,
    ...LoginRoutes,
    ...AdminRouter,
    ...EmployerRoutes,
    ...HRRoutes,
    ...HrJobRouter,
    ...ProfileRoutes,
    ...CandidateRouter,
    ...SettingsRouter,
    ...NotificationsRoutes
  ]
});

router.beforeEach(async (to, from) => {
  const authNotRequired = to.meta?.authNotRequired;
  const disabledOnSignedIn = to.meta?.disabledOnSignedIn;
  const allowed_user_types = to.meta?.allowed_user_types;
  const allowed_roles = to.meta?.allowed_roles;

  const details_fetched = store.getters["LoginStore/details_fetched"];

  if (!details_fetched) { // already handled at App.vue
    await store.dispatch("LoginStore/fetchDetails");
  }

  const isSignedIn = store.getters["LoginStore/is_signed_in"];
  const user_type = store.getters["LoginStore/user_type"];
  const user_role = store.getters["LoginStore/user_current_role"];


  const _query = { redirect: from.query.redirect ? from.query.redirect : to.query.redirect ? to.query.redirect : to.fullPath };
  store.commit("LoginStore/setState", {
    key: "route_details",
    value: { query: _query }
  });

  if (from.name === "login" && to.name !== "login" && to.name !== "forgot_password") {
    location.reload();
  }

  if (authNotRequired) {
    if (disabledOnSignedIn && isSignedIn) {
      let _location = "/#/";

      if (_query.redirect && _query.redirect !== "/" && !_query.redirect.includes("/login/")) _location += "?redirect=" + _query.redirect;

      window.location.href = _location;
    }

    return;
  }

  if (isSignedIn) {
    //Check if "to" route allows this type of user
    if (!allowed_user_types || allowed_user_types.length < 1) return { path: from.fullPath };

    if (allowed_user_types.indexOf("all") > -1) {
      if (user_type === "employer") {
        if (!allowed_roles || allowed_roles.indexOf("all") > -1) return;
        else if (allowed_roles.indexOf(user_role) === -1) return { path: from.fullPath };
      }
      return;
    }

    if (allowed_user_types.indexOf(user_type) === -1) return { path: from.fullPath };

    if (user_type === "employer") {
      if (!allowed_roles || allowed_roles.indexOf("all") > -1) return;
      else if (allowed_roles.indexOf(user_role) === -1) return { path: from.fullPath };
    }
    return;
  }

  return {
    path: "/login",
    query: _query
  };
});

export default router;
